<template>
  <div class="imgBox min-w-1400">
    <div class="imgBox1 min-w-1400">
      <div class="img1 max-w-1400">
        <img :src="importedImg1" alt="" class="imgItem1" />
      </div>
    </div>
  

   
 
  
  
  </div>
</template>

<script>
import "./index.css";
import importedImg1 from "@/page/consedeefectivo/components/img/footer.png";

export default {
  data() {
    return {
      importedImg1: importedImg1,
   
      altText: "图片",
    };
  },
};
</script>

<style scoped>
.imgBox {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
}
.imgBox1,
.imgBox2,
.imgBox4,
.imgBox6,
.imgBox7 {
  display: flex;
  justify-content: center;
  width: 100%;
  min-width: 1400px;
  background-color: "";
}

.imgBox3,
.imgBox5 {
  display: flex;
  justify-content: center;
  width: 100%;
}
img {
  display: block;
}
.img1,
.img2,
.img4,
.img6,
.img7 {
  width: 100%;
  overflow: hidden;
  max-width: 1620px;
  margin-left: 0;
  margin-right: 0;
  justify-content: space-between;
}
.img4{
  margin-bottom: 90px;
}
.img3 {
  margin-left: 145px;
  margin-right: 145px;
  margin-bottom: 50px;
}
.img5 {
  margin-left: 45px;
  margin-right: 45px;
  margin-bottom: 70px;
}
.img6{
  margin-bottom: 80px;
}
.img7{
  margin-bottom: 80px;
}
.imgItem1 {
  width: 100%;
  overflow: hidden;
  margin-left: 0;
  margin-right: 0;
}
.imgItem4 {
  width: 100%;
  overflow: hidden;
  margin-left: 0;
  margin-right: 0;
}

.imgItem6 {
  width: 100%;
  overflow: hidden;
  margin-left: 0;
  margin-right: 0;
}
.imgItem7 {
  width: 100%;
  overflow: hidden;
  margin-left: 0;
  margin-right: 0;
}

.imgItem2 {
  width: 100%;
  overflow: hidden;
  margin-bottom: 80px;
}
.imgItem3 {
  max-width: 100%;
}
.imgItem5 {
  max-width: 100%;
}
</style>
