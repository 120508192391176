<template>
  <div class="imgBox min-w-1400">
    <div class="imgBox1 min-w-1400">
      <div class="img1 max-w-1400">
        <img :src="importedImg1" alt="" class="imgItem1" />
      </div>
    </div>
   
  </div>
</template>

<script>
import "./index.css";
import importedImg1 from "@/page/consedeefectivo/components/img/2_1.png";


export default {
  data() {
    return {
      importedImg1: importedImg1,
     
      altText: "图片",
    };
  },
};
</script>

<style scoped>
.imgBox {
  display: flex;
  flex-direction: column;
  background-color: white;
}
.imgBox1{
 display: flex;
  justify-content: center;
  width: 100%;
  background-color: #F3F3F3 ;
}


img {
  display: block;
}
.img1{
  width: 100%;
  overflow: hidden;
  max-width: 1620px;
   margin-left: 45px;
  margin-right: 45px;
  justify-content: space-between;
}

.imgItem1 {
 width: 100%;
  overflow: hidden;
  margin-top: 60px;
  margin-bottom: 60px;
}

</style>
