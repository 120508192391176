<template>
  <div class="imgBox min-w-1400">
    <div class="imgBox1 min-w-1400">
      <div class="img1 max-w-1400">
         <!-- <img :src="productImg1" :alt="altText" class="imgItem" /> -->
      </div>
    </div>
    <div class="imgBox2 min-w-1400">
      <div class="img2  max-w-1400">
        <img
          :src="productImg2"
          :alt="altText"          class="imgItem2"
        />
      </div>
    </div>
   
  </div>
</template>

<script>

import productImg2 from "@/page/consedeefectivo/components/img/4_2.png";
export default {
  data() {
    return {
      productImg2: productImg2,
      altText: "图片",
    };
  },
};
</script>

<style scoped>
.imgBox {
  display: flex;
  flex-direction: column;
  background-color: #F3F3F3 ;
}
.imgBox1 {
  width: 100%;
  height: 250px;
  background-color: #F3F3F3  ;

}
.imgBox2 {
  display: flex;
  justify-content: center;
  max-width: 1400px;
  padding-top: 40px;
  padding-bottom: 20px;
  height: 5850px;
  background-color: #FFFFFF   ;
  border-radius: 32px;
  margin-top: -190px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 50px;
}
img {
  display: block;
}
.img1 {
  overflow: hidden;
  width: 100%;
}
.img2,
.img3,
.img4 {
  overflow: hidden;
}
.imgItem1 {
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
}
.imgItem2 {
 max-width: 100%;
 
}
</style>
