<template>
  <nav
    :class="{
      'black-bg': $route.path === '/about' || $route.path === '/protocol',
    }"
    class="navbar min-w-1400"
  >
    <div class="secondBox max-w-1400">
      <ul class="nav-links">
        <li :class="{ 'active-link': isActive('/') }" class="Li1">
          <router-link to="/" class="nav-link1">
            <span class="routeText1">Sobre nosotros</span>
          </router-link>
        </li>
        <li :class="{ 'active-link': $route.path === '/about' }" class="Li2">
          <router-link to="/about" class="nav-link2">
            <span class="routeText2">Módulo de noticias</span>
          </router-link>
        </li>
        <li>
          <img
            :src="require('@/page/consedeefectivo/components/img/logo.png')"
            :alt="logo"
            class="logoImg"
          />
        </li>
        <li :class="{ 'active-link': $route.path === '/service' }" class="Li3">
          <router-link to="/service" class="nav-link3">
            <span class="routeText3">Servicio de producto</span>
          </router-link>
        </li>
        <li :class="{ 'active-link': $route.path === '/protocol' }" class="Li4">
        <a href="https://miwcf.consedefectivo.com/help/privacy_agreement" class="nav-link4"> <span class="routeText4">Acuerdo de privacidad</span></a>

           
   
        </li>
      </ul>
    </div>
    <!-- <div style="height: 130px"></div> -->
  </nav>
</template>

<script>
export default {
  data() {
    return {
      logo: "NAM A ASSET MANAGEMENT COMPANY LIMITED",
      backgroundImage:
        "../../src/page/consedeefectivo/components/img/Rectangle.png",
      link1Color: "white", // 初始颜色，可以是你希望的颜色
    };
  },
  methods: {
    isActive(route) {
      return this.$route.path === route;
    },
  },
};
</script>
<style scoped>
.navbar {
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: #ffffff;
}
.black-bg {
  background-color: #f3f3f3;
}
.secondBox {
  display: flex;
  width: 90%;
  justify-content: space-between;
  height: 100px;
  padding: 0 15px;
  background-color: #171717;
  border-radius: 100px;
  margin-top: 20px;
}

.logoImg {
  width: 120px;
  height: 100px;
  margin-top: -30px;
}

.title {
  width: 280px;

  height: 24px;
  font-size: 20px;
  font-family: SF Pro Display-Medium;
  font-weight: 500;
  color: #333333;
  line-height: 23px;
  padding-left: 18px;
  text-transform: uppercase;
}

.routeText1,
.routeText2,
.routeText3,
.routeText4,
.routeText5 {
  font-size: 20px;
  font-family: SF Pro-Regular;
  font-weight: 500;
  color: #ffffff;
  line-height: 29px;
  text-align: center;
  /* text-transform: uppercase; */
}

.routeText1 {
  width: 85px;
}

.routeText2 {
  width: 158px;
}

.routeText3 {
  width: 160px;
}

.routeText4 {
  width: 160px;
  text-align: center;
}

.nav-link1,
.nav-link2,
.nav-link3,
.nav-link4,
.nav-link5 {
  text-decoration: none;
  height: 29px;
  font-weight: 500;
  line-height: 20px;
  margin: 0 auto;
}

.nav-links {
  list-style: none;
  display: flex;
  justify-content: space-between; /* 根据布局调整 */
  align-items: center;
}

.nav-links li {
  display: flex;
  padding: 12px;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  height: 59px;
  padding-left: 6px;
  padding-right: 6px;
  border: 2px solid transparent;
  border-radius: 100px;
  font-size: 18px;
}
ul {
  width: 100%;
}
.Li1 {
  width: 330px;
  margin-left: 20px;
}
.Li2 {
  width: 340px;
}
.Li3 {
  width: 400px;
}
.Li4 {
  width: 370px;
}

.active-link {
  border-color: #34a853;
  background-color: #018b63;
}

.active-link .routeText1,
.active-link .routeText2,
.active-link .routeText3,
.active-link .routeText4,
.active-link .routeText5 {
  color: #ffffff;
}
</style>